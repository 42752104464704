import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import ContactInfo from "../components/contact/contactInfo"
import "../styles/pages-global.less"


const Contact = () => (
  <>
    <SEO title="Kontakt" />
    <Layout>
      <section className="page-container">
        <div className="page-content container">
          <ContactInfo />
        </div>
      </section>
    </Layout>
  </>
)
export default Contact
