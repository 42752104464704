import React from "react"

import "./contact.less"
import "../../styles/layout.less"

const adressData = [
  {
    label: "email",
    text: "info@master-max.pl",
  },
  {
    label: "phone",
    text: "731 506 950",
  },
  {
    label: "opening-hours",
    text: "10:00-18:00",
  },
]

const ContactInfo = () => (
  <div className="container">
    <div className="row">
      <div className="col-sm-12 col-md-6 contact-text-info">
        <h2 className="section-subtitle contact-subtlitle">
          Nasza lokalizacja
        </h2>
        <div className="adress">
          <p>Żwirki i Wigury 4A/3</p>
          <p>Pawilony przy GILDII</p>
          <p>80-463 Gdańsk</p>
          <p className="adress--parking-info">(bezpłatny parking)</p>
        </div>
        <div className="opening-hours">
          <div className="footer-link-bullet"></div>
          <div className="opening-hours--data">
            pn. - pt. {adressData[2].text}
          </div>
        </div>
        <div>
          <p>{adressData[0].text}</p>
          <p>{adressData[1].text}</p>
        </div>
      </div>
      <div className="col-sm-12 col-md-6">
        <iframe
          title="map"
          className="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2323.0759892544893!2d18.608689515878027!3d54.39097278021116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd74c7f4437d59%3A0x5c3fb9dd95a8e4ec!2sABC%20Master-Max!5e0!3m2!1spl!2spl!4v1594754138033!5m2!1spl!2spl"
          width="100%"
          height="400"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
        ></iframe>
      </div>
    </div>
  </div>
)

export default ContactInfo
